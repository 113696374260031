/*
 * @source: https://codeberg.org/kytta/toot/src/branch/main/src/scss/_variables.scss
 *
 * toot - Cross-instance share page for Mastodon
 * Copyright (C) 2020-2022  Nikita Karamov <me@kytta.dev>
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 */

$text-font: "Roboto", sans-serif;

$bg: #1f232b;
$text: #9baec8;
$title: #d9e1e8;

$button-bg: #2b90d9;
$button-hover-bg: #56a7e1;
$button-text: white;

$input-bg: #131419;

$border-color: #303643;
