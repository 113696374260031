/*!
 * @source: https://codeberg.org/kytta/toot/src/branch/main/src/scss/style.scss
 *
 * toot - Cross-instance share page for Mastodon
 * Copyright (C) 2020-2022  Nikita Karamov <me@kytta.dev>
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 */
/*
 * @source: https://codeberg.org/kytta/toot/src/branch/main/src/scss/_fonts.scss
 *
 * toot - Cross-instance share page for Mastodon
 * Copyright (C) 2020-2022  Nikita Karamov <me@kytta.dev>
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), url("/fonts/Roboto-400.woff2?v=29") format("woff2"), url("/fonts/Roboto-400.woff?v=29") format("woff");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"), url("/fonts/Roboto-500.woff2?v=29") format("woff2"), url("/fonts/Roboto-500.woff?v=29") format("woff");
}
/*
 * @source: https://codeberg.org/kytta/toot/src/branch/main/src/scss/_variables.scss
 *
 * toot - Cross-instance share page for Mastodon
 * Copyright (C) 2020-2022  Nikita Karamov <me@kytta.dev>
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  background-color: #1f232b;
  color: #9baec8;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  height: 100%;
  width: 100%;
}

body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

header {
  border-bottom: 1px solid #303643;
  padding: 1.5rem 0;
  text-align: center;
  width: 100%;
}
header h1 {
  display: inline-block;
  margin: 0 1rem 0 0;
  vertical-align: middle;
}
header p {
  display: inline-block;
  line-height: 1.2rem;
  margin: 0;
  text-align: left;
  vertical-align: middle;
}
header p img {
  vertical-align: middle;
}

main {
  width: 100%;
  max-width: 600px;
  margin: auto;
  padding: 0 0.75rem;
}
main form section {
  margin-bottom: 1rem;
}
main form section.remember, main form section.submit {
  text-align: center;
}
main form section.submit {
  margin-top: 2rem;
}
main form label {
  font-size: 14px;
  margin-bottom: 8px;
  display: inline-block;
}
main form textarea,
main form input {
  font-size: 1rem;
  border-radius: 4px;
  transition: background-color 300ms ease, border 300ms ease;
}
main form textarea[type=checkbox],
main form input[type=checkbox] {
  margin-right: 8px;
}
main form textarea[type=submit],
main form input[type=submit] {
  display: inline-block;
  text-align: center;
  background-color: #2b90d9;
  color: white;
  font-weight: 500;
  font-family: inherit;
  height: 2.5rem;
  padding: 0 1rem;
  line-height: 36px;
  border: 0;
  cursor: pointer;
}
main form textarea[type=submit]:hover,
main form input[type=submit]:hover {
  background-color: #56a7e1;
}
main form textarea,
main form input[type=url] {
  color: inherit;
  width: 100%;
  outline: 0;
  font-family: inherit;
  resize: vertical;
  background-color: #131419;
  border: 1px solid #0e0f13;
  padding: 10px;
}
main form textarea:focus, main form textarea:active,
main form input[type=url]:focus,
main form input[type=url]:active {
  border: 1px solid #2b90d9;
  background-color: #1d1f26;
}
main form textarea::placeholder,
main form input[type=url]::placeholder {
  color: inherit;
  opacity: 0.3;
}

footer {
  border-top: 1px solid #303643;
  padding: 1rem 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
footer section {
  margin: 0.5rem 1rem;
}
footer section a {
  color: inherit;
  text-decoration: none;
}
footer section a:hover {
  text-decoration: underline;
}