/*!
 * @source: https://codeberg.org/kytta/toot/src/branch/main/src/scss/style.scss
 *
 * toot - Cross-instance share page for Mastodon
 * Copyright (C) 2020-2022  Nikita Karamov <me@kytta.dev>
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 */

@use "sass:color";
@import "fonts";
@import "variables";

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html,
body {
	background-color: $bg;
	color: $text;
	font-family: $text-font;
	font-size: 16px;
	font-weight: 400;
	line-height: 1;

	height: 100%;
	width: 100%;
}

body {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}

header {
	border-bottom: 1px solid $border-color;
	padding: 1.5rem 0;
	text-align: center;
	width: 100%;

	h1 {
		display: inline-block;
		margin: 0 1rem 0 0;
		vertical-align: middle;
	}

	p {
		display: inline-block;
		line-height: 1.2rem;
		margin: 0;
		text-align: left;
		vertical-align: middle;

		img {
			vertical-align: middle;
		}
	}
}

main {
	width: 100%;
	max-width: 600px;
	margin: auto;
	padding: 0 0.75rem;

	form {
		section {
			margin-bottom: 1rem;

			&.remember,
			&.submit {
				text-align: center;
			}

			&.submit {
				margin-top: 2rem;
			}
		}

		label {
			font-size: 14px;
			margin-bottom: 8px;
			display: inline-block;
		}

		textarea,
		input {
			font-size: 1rem;
			border-radius: 4px;

			&[type="checkbox"] {
				margin-right: 8px;
			}

			&[type="submit"] {
				display: inline-block;
				text-align: center;
				background-color: $button-bg;
				color: $button-text;
				font-weight: 500;
				font-family: inherit;
				height: 2.5rem;
				padding: 0 1rem;
				line-height: 36px;
				border: 0;
				cursor: pointer;

				&:hover {
					background-color: $button-hover-bg;
				}
			}

			transition: background-color 300ms ease, border 300ms ease;
		}

		textarea,
		input[type="url"] {
			color: inherit;
			width: 100%;
			outline: 0;
			font-family: inherit;
			resize: vertical;
			background-color: $input-bg;
			border: 1px solid color.scale($input-bg, $lightness: -25%);
			padding: 10px;

			&:focus,
			&:active {
				border: 1px solid $button-bg;
				background-color: color.scale($input-bg, $lightness: +5%);
			}

			&::placeholder {
				color: inherit;
				opacity: 0.3;
			}
		}
	}
}

footer {
	border-top: 1px solid $border-color;
	padding: 1rem 0;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;

	section {
		margin: 0.5rem 1rem;

		a {
			color: inherit;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}
}
